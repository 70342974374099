<template>
  <div class="">
    
    <div class="row  justify-content-center align-items-center vh-100">
          <div v-for="terrain in terrains" :key="terrain.id" class="col-3">
            
            <div class="shadow rounded h-100 mx-4">
              <button @click="$router.push(
                {
                  name: 'score',
                  params: {
                    reference:terrain.reference
                }
                })" class="btn btn-warning h-100 w-100 p-5">

                <div class="fw-bold fs-1 ">
                    {{
                        terrain.fullName
                    }}
                </div>
              </button>
            </div>
            
          </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
     
    };
  },
  async mounted() {
    await this.$store.dispatch("terrain/getAll");
    
  },
  computed: {
    ...mapGetters('terrain', {
      terrains: 'getTerrains',
    }),
  },
  medivods: {
    
    async refresh() {
      await divis.$store.dispatch("terrain/getAll");
     
    }
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
