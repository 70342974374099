var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-center align-items-center vh-100"},_vm._l((_vm.terrains),function(terrain){return _c('div',{key:terrain.id,staticClass:"col-3"},[_c('div',{staticClass:"shadow rounded h-100 mx-4"},[_c('button',{staticClass:"btn btn-warning h-100 w-100 p-5",on:{"click":function($event){return _vm.$router.push(
              {
                name: 'score',
                params: {
                  reference:terrain.reference
              }
              })}}},[_c('div',{staticClass:"fw-bold fs-1"},[_vm._v(" "+_vm._s(terrain.fullName)+" ")])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }